import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VuexPersist from 'vuex-persist';


Vue.use(Vuex)

Vue.use(VueAxios, axios)
const vuexLocalStorage = new VuexPersist({
 key: 'vuex', 
 storage: window.localStorage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
})
//window.localStorage.clear();

var apiUrl = "http://localhost/footfest/public_html/"
switch(document.location.host)
{
	case "www.scanandbook.co.uk":
		apiUrl = "https://api.scanandbook.co.uk/footfest/";
		break;
		case "www.scanandbook-preview.co.uk" :
		apiUrl= "https://api.scanandbook-preview.co.uk/";
		break

}


export default new Vuex.Store({
	plugins: [vuexLocalStorage.plugin],
	modules: {},
	state: {
		loggedIn: false,
		postCodeApiKey: '-irPVaTM6kmJKo6itSwMBQ12755',
		apiUrl: apiUrl,
		secToken: null,
		appKey: "6A26879E-DEE9-4B81-B15C-BA6861E44532",
		eventYear: "2024",
		eventDisplayDate: 'Saturday 1st June 2024',
		paragraph1Text: 'The full Challenge course covers just over 26 miles and consists of two loops, based around Farthingstone village, forming a figure of eight route which is almost entirely on footpaths and trails with minimal road usage. The course covers stretches of the Macmillan Way, Knightly Way and Nene Way with connecting footpaths through the wonderful rolling countryside of Northamptonshire.',
		paragraph2Text: 'There are 7 checkpoints on the route (3 on each loop and one at the Village Hall) and refreshments are available at each. The checkpoints will close through the day with the latest at 18.00: walkers/runners will be asked to retire if they are deemed unable to reach the end within the times.',
		EntryFee26MilesText: '£21.00 (£24.00 on the day)',
		EntryFee26Miles: '21.00',
		EntryFee15MilesText: '£18.00 (£20.00 on the day)',
		EntryFee15Miles: '18.00',
		entryInlcudesText1: 'Tea or coffee and toast on arrival',
		entryInlcudesText2: 'Biscuits (sweet and salted), cake and drinks at all 7 checkpoints',
		entryInlcudesText3: 'A meal plus dessert at the finish in the Village Hall',
		entryInlcudesText4: 'A free BEER (or alternative) at the finish',
		entryInlcudesText5: 'Certificates for all finishers',
		entryInlcudesText6: 'Results e-mailed to you',
		alertText: '',
		startAndFinishText: 'The start and finish are at Farthingstone Village Hall (NN12 8HE)',
		paymentAddress : ['Andy de Hutiray','Cleavers Cottage','West Farndon','Woodford Halse','Northants','NN11 3TU']
	},   
	mutations:{
		logOut()
		{				
			this.state.loggedIn = false
		},
		logIn(state, payload)
		{				
		
			this.state.secToken = payload.secToken
			this.state.loggedIn = true
		}
	}
})
