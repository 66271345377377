import Vue from 'vue'
import App from './App.vue'
import moment from 'moment'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import store from './store';

import '@progress/kendo-ui' // This will import the entire Kendo UI library
// As an alternative, you could import only the scripts that are used by a specific widget:
// import '@progress/kendo-ui/js/kendo.grid' // Imports only the Grid script and its dependencies
import '@progress/kendo-ui/js/cultures/kendo.culture.en-GB.js'
import { Grid, GridColumn, GridInstaller } from '@progress/kendo-grid-vue-wrapper';
import { DataSourceInstaller } from '@progress/kendo-datasource-vue-wrapper';
import { Button, ButtonsInstaller } from '@progress/kendo-buttons-vue-wrapper';
//import { Chart, ChartSeriesItem, SotckChart, Sparkline, SparklineSeriesItem, ChartInstaller } from '@progress/kendo-charts-vue-wrapper';
import JSZip from 'jszip';
//import $ from 'jquery';
import '@progress/kendo-theme-default/dist/all.css'
import { registerLicense } from '@syncfusion/ej2-base'

registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF1cWWhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZiW39fcHNVRmRfV0BwVg==');

//Vue.use(ChartInstaller);
//kendo.culture("en-GB");
window.JSZip = JSZip;
//Vue.use(DateinputsInstaller);
Vue.use(ButtonsInstaller);
Vue.use(Button);
Vue.use(GridInstaller)
Vue.use(Grid)
Vue.use(GridColumn)
Vue.use(DataSourceInstaller);


Vue.use(BootstrapVue)
Vue.use(require('vue-moment'))
Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false
Vue.config.silent = true
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import router from './router';
Vue.use(VueRouter);
Vue.use(require('vue-moment'));
Vue.config.productionTip = false
Vue.use(Vuex);

new Vue({
	router,
	store,
	moment,
  render: h => h(App)
}).$mount('#app')
