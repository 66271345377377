import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';


const LoginAdmin  = () => import('../components/LoginAdmin')
const LoginProcess = () => import('../components/LoginProcess')
const EntryForm = () => import('../components/EntryForm')
const AdminReports = () => import('../components/AdminReports')
//const EntryReport = () => import('../components/EntryReport')
const AdminDashboards = () => import('../components/AdminDashboard')
const AdminSettings = () => import('../components/AdminSettings')
Vue.use(Router);
/* var base = "/"
switch(document.location.host)
{
	case "www.scanandbook.co.uk":
		base= "/footfest/";
		break;	
}
 */

const router = new Router({
   mode: 'history',
	base: '/footfest',
  routes: [
	{
		path: '/',
		name: 'Register',
		meta: { displayTitle : "Register"},
		component: EntryForm
	},
	{
		path: '/register',
		name: 'Register',
		meta: { displayTitle : "Register"},		
		component: EntryForm
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		meta: { displayTitle : "Dashboard"},
		component: AdminDashboards
	},
    {
		path: '/adminSettings',
		name: 'AdminSettings',
		meta: { displayTitle : "Settings"},		
		component: AdminSettings,
	},   
    {
		path: '/reports',
		name: 'Reports',
		meta: { displayTitle : "Entry Report"},
		component: AdminReports,
	},   
    {
      path: '/login',
      name: 'Login',
      component: LoginAdmin,
    },   
	{
		path: '/processLogin',
		name: 'ProcessLogin',
		component: LoginProcess
	}
  ]
});

	router.beforeEach((to, from, next) => {
		if(to.path === '/processLogin')
		{
			if(store.state.loggedIn)
			{
				next('/dashboard')
			}
			else
			{
				store.commit('login')
				next()
			}			
		}
		else if(to.path ==='/register') // if path is register you do not need to be logged in so just go to the page
		{
			next()
		}
		else if(!store.state.loggedIn && to.path !== '/login') // if logged out and the path is not the login page then redirect to the login page
		{
			console.log('not logged in')
			next('/login')
		}
		else if(store.state.loggedIn && to.path === '/login')
		{
			next('/dashboard')
		}
		else {
			next()
		}
	}) 


export default router;