<template>
  <div id="app">
    <router-view/>
  </div>  
</template>

<script>


export default {
  name: 'App',
  components: {
 
  },
    data(){
      return {

    }
  }
}
</script>

<style>
#app {
  font-family: cambria;
  text-align: center;
  font-size: 12pt;
  font-weight: normal;
  color: #000000;
  margin-top: 60px;
}

@media only screen and (max-width: 992px) {

#app {
  text-align: center;
  margin-top: 10px;
  }

}

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
</style>
